import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

import {ActivityInRibbon} from '../models/ActivityInRibbon';

import {BehaviorSubject} from 'rxjs';
import {ActivityLogService} from './ActivityLogService';
import {Activity} from '../models/Activity';

import type {Config} from '../config';

export interface SwitchActivityResponse extends Response {
    readonly data: {
        id: string
    }
}

export class ActivityService {
    static $inject = ['$http', 'activityLogService', 'config'];

    private activityServiceUrl = '/ship/rest/activity';

    private activities: ActivityInRibbon[];

    private startingVoyage: boolean;

    allowedActivities: BehaviorSubject<ActivityInRibbon[]>;

    private currentActivity: Activity;

    constructor(private $http, readonly activityLogService: ActivityLogService, readonly config: Config) {
        this.allowedActivities = new BehaviorSubject([]);
        this.startingVoyage = false;
        this.activityLogService.getCurrentActivity().then((current) => {
            this.currentActivity = current;
        });

        this.activityLogService.subscribe(this.checkIfUpdatesAreNeeded);
    }

    getActivities(): void {
        this.$http.get(this.activityServiceUrl)
            .then((response) => {
                this.activities = orderBy(filter(response.data, {active: true}), ['order']);
            }).catch((err) => {
            console.log(`Failed to get activities; ${JSON.stringify(err)}`);
        });
    }

    /**
     * Returns a list of activity names which when clicked on will present the user with a prompt whether they want
     * to end the voyage as well
     */
    getVoyageEndingActivityNames(): string[] {
        return this.config.VOYAGE_ENDING_ACTIVITIES;
    }

    switchActivity(activity: ActivityInRibbon, jobId?: string): angular.IPromise<any> {
        const payload = {
            activityId: activity.id,
            jobId: jobId,
        };
        return this.$http.post(this.activityServiceUrl, payload);
    }

    updateRemark(id: string, remark: string): angular.IPromise<any> {
        return this.$http.put(this.activityServiceUrl + '/' + id + '/remark',
            remark,
        );
    }

    updateEquipment(id: string, equipment: any): angular.IPromise<any> {
        return this.$http.put(this.activityServiceUrl + '/' + id + '/equipment',
            equipment,
        );
    }

    private checkIfUpdatesAreNeeded = (currentActivityFromServer: Activity | null) => {
        if (!currentActivityFromServer) {
            return;
        }

        const activityIsChanged = () => {
            if (this.currentActivity && this.currentActivity.id) {
                return this.currentActivity.id !== currentActivityFromServer.id;
            }

            return !this.currentActivity && currentActivityFromServer;
        };

        if (activityIsChanged()) {
            this.currentActivity = currentActivityFromServer;
            this.updateAllowedActivities(currentActivityFromServer);
        }
    };

    updateAllowedActivities(currentActivity: Activity): void {
        // Only display a subset of activities based on restrictions set in config
        const activitiesAtVoyageStart = this.config.RESTRICTED_ACTIVITIES['voyageStart'];
        if (!this.config.ENFORCE_ACTIVITY_ORDER) {
            this.allowedActivities.next(this.activities);
        } else if (this.startingVoyage && !currentActivity && activitiesAtVoyageStart !== undefined) {
            this.allowedActivities.next(
                this.activities.filter((a) => activitiesAtVoyageStart.includes(a.name)),
            );
        } else if (!currentActivity) {
            this.allowedActivities.next(this.activities);
        } else if (Object.keys(this.config.RESTRICTED_ACTIVITIES).includes(currentActivity.activityName)) {
            this.allowedActivities.next(
                this.activities.filter((a) => this.config.RESTRICTED_ACTIVITIES[currentActivity.activityName].includes(a.name)),
            );
        } else if (this.startingVoyage && activitiesAtVoyageStart !== undefined) {
            this.allowedActivities.next(
                this.activities.filter((a) => activitiesAtVoyageStart.includes(a.name)),
            );
        } else {
            this.allowedActivities.next(this.activities);
        }
    }

    setStartingVoyage(startingVoyage: boolean): void {
        this.startingVoyage = startingVoyage;
        this.updateAllowedActivities(this.currentActivity);
    }
}

import {IComponentOptions, IController, IOnInit, IOnDestroy} from 'angular';
import {ActivityInRibbon} from '../../models/ActivityInRibbon';
import {Job} from '../../models/Job';

class ActivityRibbonCtrl implements IController, IOnInit, IOnDestroy {
    // Each app should register its own ActivityService with the injector
    static $inject = ['activityService', '$timeout'];

    private onSelect: (ActivityInRibbon, endVoyage) => void;
    private errorMessage: string;
    private isDisabled: boolean;
    private disableOnSelect = false;

    private showEndVoyagePrompt = false;

    private activities: ActivityInRibbon[];
    private selectedActivityId?: string;
    private selectedActivity?: ActivityInRibbon;
    private debounceTimeout: Promise<any>;
    private activeJob?: Job;

    constructor(readonly activityService: any, // ActivityService | ActivityServiceForShore
                readonly $timeout) {
    }

    $onInit() {
        this.activityService.getActivities();
        this.activityService.allowedActivities.subscribe(this.updateActivities);
    }

    $onDestroy() {
        this.$timeout.cancel(this.debounceTimeout);
    }

    updateActivities = (allowedActivities: ActivityInRibbon[]) => {
        this.activities = allowedActivities;
    }

    select = (activity: ActivityInRibbon) => {
        // Open the prompt if
        //  1. There's an active voyage
        //  2. The activity name is in the activities which can end the voyage
        if (
            this.activeJob &&
            this.activityService.getVoyageEndingActivityNames().find((name) => name === activity.name)
        ) {
            this.setSelected(activity);
            this.showEndVoyagePrompt = true;
        }

        if (this.isDisabled || this.showEndVoyagePrompt) {
            return;
        }

        if (this.disableOnSelect) {
            this.isDisabled = true;
        }

        this.$timeout.cancel(this.debounceTimeout);

        this.debounceTimeout = this.$timeout(() => {
            this.setSelected(activity);
            this.onSelect(activity, false);
        });
    };

    setSelected = (newSelectedActivity) => {
        if (newSelectedActivity) {
            this.selectedActivity = newSelectedActivity;
            this.selectedActivityId = newSelectedActivity.id;
        }
    };

    selectPrompt = (activity: ActivityInRibbon, endVoyage: boolean) => {
        this.showEndVoyagePrompt = false;

        this.$timeout.cancel(this.debounceTimeout);

        this.debounceTimeout = this.$timeout(() => {
            this.setSelected(activity);
            this.onSelect(activity, endVoyage);
        });
    }
}

export class ActivityRibbonComponent implements IComponentOptions {
    readonly controller = ActivityRibbonCtrl;
    readonly templateUrl = 'activity-ribbon/activity-ribbon.component.html';
    readonly bindings = {
        onSelect: '<',
        selectedActivityId: '=?',
        activeJob: '<?',
        errorMessage: '=?',
        isDisabled: '=?',
        disableOnSelect: '<?',
    };
}
